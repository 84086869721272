import * as Yup from "yup";

const PurchaseSchema = Yup.object({
    transactionType: Yup.string()
        .typeError("Transaction type is required")
        .required("Transaction type is required"),

    productId: Yup.string()
        .typeError("Product is required")
        .required("Product is required"),

    quantity: Yup.number()
        .typeError("Quantity is required")
        .required("Quantity is required")
        .positive("Quantity must be greater than 0")
        .integer("Quantity must be an integer"),

    remark: Yup.string()
});

export default PurchaseSchema;
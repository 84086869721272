import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import Navbar from "./app/Navbar";
import {ScrollTop} from "./app/ScrollTop";
import Home from "./Home";
import {ProductsList, AddProductForm, EditProductForm} from "./features/product";
import {AddPurchaseForm, PurchasesList, EditPurchaseForm } from "./features/purchase";
import {AddSaleForm} from "./features/sale";

function App() {
    return (
        <>
            <div className="min-vh-100">
                <Router>
                    <Navbar/>
                    <ScrollTop />
                    <Switch>
                        <Route exact path="/" component={Home} />

                        {/* <Route exact path="/transactions" component={CategoriesList} /> */}
                        {/* <Route exact path="/categories/create" component={AddCategoryForm} />
                        <Route exact path="/categories/:categoryId" component={EditCategoryForm} /> */}

                        <Route exact path="/products" component={ProductsList} />
                        <Route exact path="/products/create" component={AddProductForm} />
                        <Route exact path="/products/:productId" component={EditProductForm} />

                        <Route exact path="/transactions" component={PurchasesList} />
                        <Route exact path="/transactions/create" component={AddPurchaseForm} />
                        <Route exact path="/in" component={AddPurchaseForm} />
                        <Route exact path="/out" component={AddPurchaseForm} />
                        <Route exact path="/transactions/:purchaseId" component={EditPurchaseForm} />

                        <Route exact path="/sales" component={AddSaleForm} />
                        {/* <Route exact path="/sales/create" component={AddSaleForm} /> */}
                        {/* <Route exact path="/sales/:saleId" component={EditSaleForm} /> */}

                        {/* <Route exact path="/suppliers" component={SuppliersList} />
                        <Route exact path="/suppliers/create" component={AddSupplierForm} />
                        <Route exact path="/suppliers/:supplierId" component={EditSupplierForm} /> */}

                    </Switch>
                </Router>
            </div>
        </>
    );
}

export default App;

import {useEffect, useState, useMemo} from "react";
import {useHistory} from "react-router-dom";
import {Formik} from "formik";

import {useAddNewPurchaseMutation} from "./purchaseSlice";
import {useGetProductsQuery} from "../product/productSlice";
import {Input, SSelect, TextArea} from "../../app/form/fields";
import FormCard from "../../app/card/FormCard";
import ButtonSpinner from "../../app/spinners/ButtonSpinner";
import PurchaseSchema from "./PurchaseSchema";
import {Product} from "../api";
import {Message} from "../../app/index";


export const AddPurchaseForm = () => {
    const [message, setMessage] = useState<Message | null>(null);
    const [addNewPurchase] = useAddNewPurchaseMutation();
    const allProducts = useGetProductsQuery("?limit=all");
    const products = useMemo(() => {
        if (allProducts.isSuccess && allProducts.data.products) {
            return allProducts.data.products.map( (product: Product) => ({ value: product.id, label: product.name }))
        }
        return [{ value: "", label: "No results found" }]
    }, [allProducts.isSuccess, allProducts.data?.products]);
    
    const history = useHistory();

    let value = '';
    if (history.location.pathname === '/in') value = '0';
    if (history.location.pathname === '/out') value = '1';

    useEffect(() => {
        if (message?.type && message?.message) {
            window.scrollTo(0, 0);
        }
    }, [message?.type, message?.message])

    const form = (
        <Formik
            initialValues={{
                productId: "", quantity: "", description: "", transactionType: value
            }}
            validationSchema={PurchaseSchema}
            onSubmit={async (values, actions) => {
                try {
                    values.transactionType = value;
                    const {transaction, error, invalidData} = await addNewPurchase(values).unwrap();
                    actions.setSubmitting(false);
                    if (transaction) {
                        const message = { type: "success", message: "Purchase created successfully" }
                        history.push({
                            pathname: "/transactions",
                            state: { message }
                        });
                    }
                    if (error) { setMessage({ type: "danger", message: error }) }
                    if (invalidData) {
                        actions.setErrors(invalidData);
                        setMessage({ type: "danger", message: "Please correct the errors below" });
                    }
                } catch (error) {
                    setMessage({ type: "danger", message: error.message });
                }
            }}
        >
            {props => (
                <>
                    <form onSubmit={props.handleSubmit}>
                        {/* <SSelect name="transactionType" label="Select transaction type" options={[{ label: 'In', value: '0' }, {label: 'Out', value: '1'}]} required={true} /> */}
                        <SSelect name="productId" label="Select product" options={products} required={true} />
                        <Input name="quantity" min={1} label="Quantity" type="number" placeholder="Enter quantity" required={true} />
                        <TextArea name="description" label="Remark" placeholder="Enter remark" required={false} />
                        <button
                            type="submit"
                            className="btn btn-primary rounded-0 me-2 mt-3"
                            disabled={props.isSubmitting}
                        >
                            {props.isSubmitting ? <ButtonSpinner text="Adding" /> : "Add"}
                        </button>
                    </form>
                </>
            )}
        </Formik>
    );

    return ( <FormCard title="Add Transaction" message={message} setMessage={setMessage} cardBody={form} /> )
}

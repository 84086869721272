import { useGetHomeQuery } from "./homeSlice";

const Home = () => {
    const result =useGetHomeQuery();

    const lowProducts = result?.data?.lowStocks || [];

    return (
        <div className="container-fluid pt-3">
            <div className="card-group">
                <div className="card border-0 rounded-0 text-white bg-success">
                    <div className="card-body">
                        <h2 className="card-title">Total Products</h2>
                        <p className="card-text">{result?.data?.productCount || 0}</p>
                    </div>
                </div>

                <div className="card border-0 rounded-0 text-white bg-success">
                    <div className="card-body">
                        <h3 className="card-title">Total Stock</h3>
                        <p className="card-text">{result?.data?.totalQuantity || 0}</p>
                    </div>
                </div>
            </div>

            <div className="card-group">

                <div className="card border-0 rounded-0 text-white bg-success">
                    <div className="card-body">
                        <h2 className="card-title">Low Stock - {lowProducts.length}</h2>
                        {lowProducts.map(k =>
                            <p className="card-text" style={{width: '50%'}} key={k.id}>{k.name} <span style={{ float: 'right' }}>Qty {k.quantity}</span></p>
                        )}
                    </div>
                </div>

                {/* <div className="card border-0 rounded-0 text-white bg-success">
                    <div className="card-body">
                        <h2 className="card-title">Today's Sale</h2>
                        <p className="card-text">ed</p>
                    </div>
                </div> */}

                {/* <div className="card border-0 rounded-0 text-white bg-success">
                    <div className="card-body">
                        <h5 className="card-title">Manage purchases & suppliers</h5>
                        <p className="card-text">
                            Create, read, update and delete purchases and suppliers
                        </p>
                        <p className="card-text">
                            Purchases module is used to add stock to the system
                        </p>
                    </div>
                </div> */}

                {/* <div className="card border-0 rounded-0 text-white bg-success">
                    <div className="card-body">
                        <h5 className="card-title">Manage sales</h5>
                        <p className="card-text">
                            Create, read, update and delete product sales
                        </p>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default Home;

import {emptySplitApi} from "../api/apiSlice";
import {Transactions, TransactionState, DraftTransaction, Error, FormErrors, Message} from "../api";

export const purchaseApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        getPurchases: builder.query<Transactions | Error, string | void>({
            query: (query) => ({
                url: query && query.length ? `/transaction${query}`: "/transaction",
                validateStatus: (response, result) => {
                    if (result?.error) { return true }
                    return response.ok;
                },
            }),
            providesTags: (result, error, arg) => {
                if (result?.transactions) {
                    return ["Transaction", ...result.transactions.map(({ _id: id }) => ({type: "Transaction" as const, id}))]
                } else {
                    return ["Transaction"]
                }
            }
        }),
        getPurchase: builder.query<TransactionState | FormErrors | Error, string>({
            query: (id) => ({
                url: `/transaction/${id}`,
                validateStatus: (response, result) => {
                    if (result?.invalidData || result?.error) { return true }
                    return response.ok;
                },
            }),
            providesTags: (result, error, arg) => [{ type: "Purchase", id: arg }]
        }),
        addNewPurchase: builder.mutation<TransactionState | FormErrors | Error, DraftTransaction>({
            query: (category) => ({
                url: "/transaction",
                method: "POST",
                body: category,
                validateStatus: (response, result) => {
                    if (result?.invalidData || result?.error) { return true }
                    return response.ok;
                },
            }),
            invalidatesTags: ["Transaction"]
        }),
        editPurchase: builder.mutation<TransactionState | FormErrors | Error, DraftTransaction>({
            query: (category) => ({
                url: "/transaction",
                method: "PATCH",
                body: category,
                validateStatus: (response, result) => {
                    if (result?.invalidData || result?.error) { return true }
                    return response.ok;
                },
            }),
            invalidatesTags: (result, error, arg) => [{ type: "Purchase", id: arg.id }]
        }),
        destroyPurchase: builder.mutation<Message | FormErrors | Error, string>({
            query: (id) => ({
                url: `/transaction/${id}`,
                method: "DELETE",
                validateStatus: (response, result) => {
                    if (result?.invalidData || result?.error) { return true }
                    return response.ok;
                },
            }),
            invalidatesTags: ["Purchase"]
        })
    })
})

export const {
    useGetPurchasesQuery,
    useGetPurchaseQuery,
    useAddNewPurchaseMutation,
    useEditPurchaseMutation,
    useDestroyPurchaseMutation,
} = purchaseApi;
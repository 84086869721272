import {emptySplitApi} from "./features/api/apiSlice";
import {DraftHome, TransactionState, Error, Message} from "./features/api";

export const homeApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        getHome: builder.query<DraftHome, string | void>({
            query: (query) => ({
                url: `/home`,
                validateStatus: (response, result) => {
                    if (result?.error) { return true }
                    return response.ok;
                },
            }),
            providesTags: (result, error, arg) => {
                return ["Home"];
            }
        })
    })
})

export const {
    useGetHomeQuery
} = homeApi;

